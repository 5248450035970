<!-- 精英版反馈 -->
<template>
  <div class="feedback_elite">
    <div class="fanhui">
      <el-button   type='warning' plain @click="fanhui">返回</el-button>
    </div>
    <div class="fankui">
      <el-input
        type="textarea"
        placeholder="请输入反馈内容"
        v-model="textarea"
        :autosize="{ minRows: 6, maxRows: 6}"
        maxlength="300"
        show-word-limit
      ></el-input>
      <el-button class="aaa btn" @click="yesClick">确定</el-button>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { feedback } from '@/api/eliteApi'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      textarea: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    yesClick() {
      console.log('a')
      if (!this.textarea) {
        this.$message.warning('请输入反馈内容')
        return false
      }
      feedback({
        describe: this.textarea,
      }).then((res) => {
        console.log(res)
        if (!res.code) {
          this.$message.success(res.msg)
          this.textarea = ''
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    fanhui() {
      this.$router.go(-1)
    },
    init() {},
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.feedback_elite {
  width: 1200px;
  margin: auto;
  background: #fff;
  min-height: 80vh;
  margin-top: 76px;
}
.fanhui {
  padding: 12px;
  /* background: #; */
}

.fankui {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}
.aaa {
  margin: 12px;
  float: right;
}
</style>